import {FETCH, FETCH_SUCCESS, FETCH_ERROR, UPDATE} from './actions';

const initialState = {
	data: [],
	error: null,
	isFetching: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH:
			return {
				...state,
				error: null,
				isFetching: true,
			};
		case FETCH_SUCCESS:
			return {
				...state,
				data: action.result,
				error: null,
				isFetching: false,
			};
		case FETCH_ERROR:
			return {
				...state,
				data: null,
				error: action.error,
				isFetching: false,
			};
		case UPDATE:
			return {
				...state,
				data: {
					...state.data,
					[action.locker.id]: action.locker,
				},
			};
		default:
			return state;
	}
};

export default reducer;
