import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';

import rootSaga from './sagas';
import auth, {authActions} from '../modules/auth';
import lockers from '../modules/lockers';
import users from '../modules/users';

export const history = createBrowserHistory();
const appReducer = combineReducers({
	router: connectRouter(history),
	auth,
	lockers,
	users,
});

const rootReducer = (state, action) => {
	if (action.type === authActions.SIGN_OUT) {
		state = undefined; // Clear the state
	}

	return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();
const middleware = [routerMiddleware(history), sagaMiddleware];

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	middleware.push(logger); //logger should always be the last middleware, so it can listen to everything
}

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(...middleware)),
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
