import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import {Helmet} from 'react-helmet';

const Container = ({children}) => {
	return (
		<div className="main-background container">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Prking</title>

				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<style>{'body { background-color: #CFCFCF; }'}</style>
				<link
					href="https://fonts.googleapis.com/icon?family=Material+Icons"
					rel="stylesheet"
				/>
				<link
					href="https://fonts.googleapis.com/css?family=Encode+Sans+Expanded"
					rel="stylesheet"
				/>
				<link
					rel="stylesheet"
					href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
					integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
					crossOrigin="anonymous"
				/>
				<link
					rel="stylesheet"
					href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
				/>
			</Helmet>
			{children}
		</div>
	);
};

Container.propTypes = {
	children: PropTypes.any,
};

export default Container;
