import React, {PureComponent} from 'react';
import Container from './Container';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import './styles.css';

export default class MainContainer extends PureComponent {
	state = {
		menuOpened: false,
	};

	render() {
		return (
			<Container>
				<div className="container-fluid top-header">
					<div className="row">
						<div
							onClick={() =>
								this.setState({menuOpened: !this.state.menuOpened})
							}
							className="col-sm-3 col-6 text-left pl-3 text-md-right order-2 order-sm-1">
							<i
								className={`material-icons menu-button ${
									this.state.menuOpened ? 'rotate-90' : ''
								}`}
								style={{
									fontSize: '4.25em',
									fontWeight: '200',
									lineHeight: '102px',
								}}>
								menu
							</i>
						</div>
						<div className="col-sm-6 order-1 order-sm-2">
							<p className="logo">
								PRKING<sup className="tm">TM</sup>
							</p>
						</div>
						<div className="col-sm-3 col-6 text-right pr-3 pt-2 pr-md-auto text-md-left order-3 order-sm-3">
							<Link
								style={{color: 'white'}}
								to="/login"
								onClick={this.props.onLogoutClick}>
								<p style={{lineHeight: 1.0, color: 'white', marginTop: 20}}>
									Log out
								</p>
							</Link>
						</div>
					</div>
				</div>
				{this.state.menuOpened ? (
					<div id="expandedMenu" className="overlay">
						<div className="overlay-content">
							<Link to="/">
								<i
									style={{fontSize: '600%'}}
									className="material-icons top-menu-icon">
									timeline
								</i>
								Spots
							</Link>
							<Link to="/users">
								<i
									style={{fontSize: '600%'}}
									className="material-icons top-menu-icon">
									account_circle
								</i>
								Users
							</Link>
						</div>
					</div>
				) : null}
				{this.props.children}
			</Container>
		);
	}
}

MainContainer.propTypes = {
	children: PropTypes.any,
};
