import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const ProgressBar = ({freeSpots, allSpots}) => {
	return (
		<div
			className="progressBarContainer row"
			style={{marginLeft: 0, marginRight: 0}}>
			<div className="col-sm-12 center-block">
				<div className="progress" style={{height: 20, width: '50%'}}>
					<div
						role="progressbar"
						className="progressBarFill"
						style={{width: `${(freeSpots / allSpots) * 100}%`}}
						aria-valuenow="20"
						aria-valuemin="0"
						aria-valuemax="100">
						<p>
							{freeSpots <= 2
								? freeSpots === 0
									? null
									: `${freeSpots}/${allSpots}`
								: `Free spots: ${freeSpots}/${allSpots}`}
						</p>
					</div>
					{freeSpots === 0 ? (
						<p>{`Free spots: ${freeSpots}/${allSpots}`}</p>
					) : null}
				</div>
			</div>
		</div>
	);
};

ProgressBar.propTypes = {
	children: PropTypes.any,
};

export default ProgressBar;
