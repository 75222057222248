import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const UserListItem = ({number, user, onBlockPress}) => {
	return (
		<tr>
			<th scope="row">{number}</th>
			<td>{user.name}</td>
			<td>{user.email}</td>
			<td>{user.plate}</td>
			<td>
				{user.security ? (
					<i className="material-icons block-button">done</i>
				) : null}
			</td>
			<td>
				{user.isDeleting ? (
					<i
						className=" fa fa-circle-o-notch fa-spin block-button"
						style={{fontSize: '20px'}}
					/>
				) : (
					<i
						className="material-icons block-button"
						onClick={onBlockPress}
						data-toggle="modal"
						data-target="#blockConfirmationModal">
						delete
					</i>
				)}
			</td>
		</tr>
	);
};

UserListItem.propTypes = {
	children: PropTypes.any,
};

export default UserListItem;
