export const SIGN_IN = 'prkingBarrier/auth/SIGN_IN';
export const SIGN_IN_SUCCESS = 'prkingBarrier/auth/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'prkingBarrier/auth/SIGN_IN_ERROR';
export const SIGN_OUT = 'prkingBarrier/auth/SIGN_OUT';

export const signIn = (authData, navigation) => ({
	type: SIGN_IN,
	authData,
	navigation,
});

export const signInSuccess = (result, email, admin) => ({
	type: SIGN_IN_SUCCESS,
	result,
	email,
	admin,
});

export const signInError = (error) => ({
	type: SIGN_IN_ERROR,
	error,
});

export const signOut = () => ({
	type: SIGN_OUT,
});
