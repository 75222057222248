export const FETCH = 'prkingBarrier/users/FETCH';
export const FETCH_SUCCESS = 'prkingBarrier/users/FETCH_SUCCESS';
export const FETCH_ERROR = 'prkingBarrier/users/FETCH_ERROR';
export const CREATE = 'prkingBarrier/users/CREATE';
export const CREATE_SUCCESS = 'prkingBarrier/users/CREATE_SUCCESS';
export const CREATE_ERROR = 'prkingBarrier/users/CREATE_ERROR';
export const UPDATE = 'prkingBarrier/users/UPDATE';
export const CHANGE_BLOCK = 'prkingBarrier/users/CHANGE_BLOCK';
export const CHANGE_BLOCK_SUCCESS = 'prkingBarrier/users/CHANGE_BLOCK_SUCCESS';
export const CHANGE_BLOCK_ERROR = 'prkingBarrier/users/CHANGE_BLOCK_ERROR';

export const fetchUsers = () => ({
	type: FETCH,
});

export const fetchUsersSuccess = (result) => ({
	type: FETCH_SUCCESS,
	result,
});

export const fetchUsersError = (error) => ({
	type: FETCH_ERROR,
	error,
});

export const createUser = (body, token) => ({
	type: CREATE,
	body,
	token,
});

export const createUserSuccess = (result) => ({
	type: CREATE_SUCCESS,
	result,
});

export const createUserError = (error) => ({
	type: CREATE_ERROR,
	error,
});

export const changeBlock = (userId, block) => ({
	type: CHANGE_BLOCK,
	userId,
	block,
});

export const changeBlockSuccess = (result) => ({
	type: CHANGE_BLOCK_SUCCESS,
	result,
});

export const changeBlockError = (error) => ({
	type: CHANGE_BLOCK_ERROR,
	error,
});

export const DELETE = 'prkingBarrier/users/DELETE';
export const DELETE_SUCCESS = 'prkingBarrier/users/DELETE_SUCCESS';
export const DELETE_ERROR = 'prkingBarrier/users/DELETE_ERROR';

export const deleteUser = (userId, token) => ({
	type: DELETE,
	userId,
	token,
});

export const deleteUserSuccess = (userId) => ({
	type: DELETE_SUCCESS,
	userId,
});

export const deleteUserError = (error) => ({
	type: DELETE_ERROR,
	error,
});
