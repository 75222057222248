import React from 'react';
import Container from './Container';
import PropTypes from 'prop-types';
import './styles.css';

const LoginContainer = ({children, title}) => {
	return (
		<Container>
			<div className="container pt-3">
				<div className="row">
					<div className="col-sm-12 col-md-6 offset-md-3">
						<div className="card">
							<div className="card-img-top top-header">
								<p className="logo">
									PRKING<sup className="tm">TM</sup>
								</p>
							</div>
							<div className="card-body">
								<h5 className="card-title">{title ? title : 'Login'}</h5>
								{children}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

LoginContainer.propTypes = {
	children: PropTypes.any,
};

export default LoginContainer;
