import {put, call, takeLatest} from 'redux-saga/effects';

import * as usersActions from './actions';
import firebase from 'firebase/app';
import 'firebase/firestore';

function* fetchUsers() {
	try {
		const response = yield fetchFromFirebase();
		if (response.error) {
			yield put(usersActions.fetchUsersError(response.error));
		} else {
			const docList = {};
			response.forEach((doc) => {
				docList[doc.id] = {
					id: doc.id,
					name: doc.data().name,
					email: doc.data().email,
					plate: doc.data().plate,
					security: doc.data().security,
					disabled: doc.data().disabled,
				};
			});
			yield put(usersActions.fetchUsersSuccess(docList));
		}
	} catch (e) {
		yield put(usersActions.fetchUsersError(e.message));
	}
}

function* fetchFromFirebase() {
	return yield firebase.firestore().collection('users').get();
}

const postData = (body, token) =>
	fetch('https://europe-west3-msprking-cec86.cloudfunctions.net/createUser', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
		body: JSON.stringify({
			...body,
		}),
	});

function* createUser(action) {
	try {
		const response = yield call(postData, action.body, action.token);
		const responseJson = yield response.json();

		if (!response.ok) {
			yield put(usersActions.createUserError(responseJson));
		} else {
			yield put(usersActions.createUserSuccess(responseJson));
		}
	} catch (e) {
		yield put(usersActions.createUserError(e.message));
	}
}

const postChangeBlockData = (userId, block) =>
	fetch('https://europe-west3-msprking-cec86.cloudfunctions.net/changeBlock', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			userId: userId,
			block: block,
		}),
	});

function* changeBlock(action) {
	try {
		const response = yield call(
			postChangeBlockData,
			action.userId,
			action.block,
		);
		const userJson = yield response.json();
		if (response.error) {
			yield put(usersActions.changeBlockError(response.error));
		} else {
			yield put(usersActions.changeBlockSuccess(userJson));
		}
	} catch (e) {
		yield put(usersActions.changeBlockError(e.message));
	}
}

const postDeleteUser = (userId, token) =>
	fetch('https://europe-west3-msprking-cec86.cloudfunctions.net/deleteUser', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
		body: JSON.stringify({
			userId: userId,
		}),
	});

function* deleteUser(action) {
	try {
		const response = yield call(postDeleteUser, action.userId, action.token);
		if (!response.ok) {
			yield put(usersActions.deleteUserError(response.error));
		} else {
			yield put(usersActions.deleteUserSuccess(action.userId));
		}
	} catch (e) {
		yield put(usersActions.deleteUserError(e.message));
	}
}

const lockersSagas = [
	takeLatest(usersActions.FETCH, fetchUsers),
	takeLatest(usersActions.CREATE, createUser),
	takeLatest(usersActions.DELETE, deleteUser),
	takeLatest(usersActions.CHANGE_BLOCK, changeBlock),
];

export default lockersSagas;
