import React from 'react';
import {connect} from 'react-redux';
import 'firebase/auth';
import {MainContainer} from '../components/Container';
import {withRouter} from 'react-router-dom';
import {lockersActions, lockersSelectors} from '../modules/lockers';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {ProgressBar} from '../components/ProgressBar';
import {ParkingSpot, ParkingSpotDetails} from '../components/ParkingSpot';
import {authActions, authSelectors} from '../modules/auth';

class Main extends React.Component {
	state = {
		selectedSpot: null,
	};

	handleSpotPress = (id) => {
		this.setState({
			selectedSpot:
				this.state.selectedSpot === this.props.lockers[id]
					? null
					: this.props.lockers[id],
		});
	};

	handleSpotModified = (locker) => {
		this.props.dispatch(lockersActions.updateLocker(locker));
	};

	componentDidMount() {
		this.props.dispatch(lockersActions.fetchLockers());
		this.unsubscribe = firebase
			.firestore()
			.collection('lockers')
			.onSnapshot(
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type === 'modified') {
							const locker = {
								id: change.doc.id,
								name: change.doc.data().displayName,
								occupied: !change.doc.data().isUp,
								batteryLevel: change.doc.data().batteryLevel,
								occupiedBy: change.doc.data().occupiedBy,
								hidden: change.doc.data().hidden,
							};
							this.handleSpotModified(locker);
						}
					});
				},
				function (error) {
					console.log(error);
				},
			);
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	render() {
		return (
			<MainContainer
				onLogoutClick={() => this.props.dispatch(authActions.signOut())}>
				<ProgressBar
					allSpots={
						Object.values(this.props.lockers).filter((locker) => !locker.hidden)
							.length
					}
					freeSpots={
						Object.values(this.props.lockers).filter(
							(locker) => locker.occupied !== true && !locker.hidden,
						).length
					}
				/>
				<div>
					<div className="row pb-3">
						<div className="col-sm-12 center-block">
							{this.props.lockers
								? Object.values(this.props.lockers).map((locker) =>
										!locker.hidden ? (
											<ParkingSpot
												onClick={() => this.handleSpotPress(locker.id)}
												key={locker.id}
												name={locker.name}
												occupied={locker.occupied}
											/>
										) : null,
								  )
								: null}
						</div>
					</div>
					{this.state.selectedSpot ? (
						<ParkingSpotDetails
							name={this.state.selectedSpot.name}
							occupied={this.state.selectedSpot.occupied}
							occupiedBy={this.state.selectedSpot.occupiedBy}
							batteryLevel={this.state.selectedSpot.batteryLevel}
						/>
					) : null}
				</div>
			</MainContainer>
		);
	}
}

const mapStateToProps = (state) => {
	const lockers = lockersSelectors.dataSelector(state);
	const email = authSelectors.emailSelector(state);

	return {
		lockers,
		email,
	};
};

export default withRouter(connect(mapStateToProps)(Main));
