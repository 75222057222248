export const dataSelector = (state) => {
	return state.users.data || [];
};

export const errorSelector = (state) => {
	return state.users.error;
};

export const isFetchingSelector = (state) => {
	return state.users.isFetching;
};

export const isCreatingSelector = (state) => {
	return state.users.isCreating;
};

export const isCreatedSelector = (state) => {
	return state.users.isCreated;
};

export const errorCreatingSelector = (state) => {
	return state.users.errorCreating;
};
