import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const getSpotColor = (occupied) => {
	if (!occupied) {
		return '#40B5A1';
	} else {
		return '#F15E22';
	}
};

const ParkingSpot = ({occupied, name, onClick}) => {
	return (
		<div
			onClick={onClick}
			className="parkingSpot"
			style={{background: getSpotColor(occupied)}}>
			<div style={{wordBreak: 'break-word'}}>{name}</div>
		</div>
	);
};

ParkingSpot.propTypes = {
	occupied: PropTypes.bool,
	name: PropTypes.string,
	onClick: PropTypes.func,
};

export default ParkingSpot;
