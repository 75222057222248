import React from 'react';
import {connect} from 'react-redux';
import 'firebase/auth';
import {authSelectors, authActions} from '../modules/auth';
import {LoginContainer} from '../components/Container';
import {withRouter} from 'react-router-dom';

class Login extends React.Component {
	state = {
		login: '',
		password: '',
		triedLogin: false,
	};

	handleLogin = (event) => {
		event.preventDefault();
		this.props.dispatch(authActions.signIn(this.state));
		this.setState({triedLogin: true});
	};

	handleChange = (key, value) => {
		this.setState({[key]: value});
	};

	render() {
		return (
			<LoginContainer>
				<form onSubmit={this.handleLogin}>
					<div className="form-group">
						<label className="control-label">Username</label>
						<input
							className="form-control"
							type="text"
							placeholder="Login"
							value={this.state.login}
							onChange={(event) => {
								this.handleChange('login', event.target.value);
							}}
						/>
					</div>

					<div className="form-group">
						<label className=" control-label">Password</label>
						<input
							className="form-control"
							type="password"
							placeholder="Password"
							value={this.state.password}
							onChange={(event) =>
								this.handleChange('password', event.target.value)
							}
						/>
					</div>

					<div className="form-group justify-content-center">
						<span>
							<button
								onClick={this.handleLogin}
								className="btn btn-success"
								style={{background: '#40B5A1'}}
								type="submit">
								Log in{' '}
								{this.props.isSigningIn ? (
									<i
										className=" fa fa-circle-o-notch fa-spin"
										style={{fontSize: '20px'}}
									/>
								) : null}
							</button>
						</span>
					</div>

					{this.state.triedLogin && this.props.error ? (
						<div
							className="alert alert-danger alert-dismissible fade show"
							role="alert">
							Error while logging in.
						</div>
					) : null}
				</form>
			</LoginContainer>
		);
	}
}

const mapStateToProps = (state) => {
	const token = authSelectors.tokenSelector(state);
	const activeMemberUuid = authSelectors.activeMemberUuidSelector(state);
	const email = authSelectors.emailSelector(state);
	const error = authSelectors.errorSelector(state);
	const isSigningIn = authSelectors.isSigningInSelector(state);

	return {
		token,
		activeMemberUuid,
		email,
		error,
		isSigningIn,
	};
};

export default withRouter(connect(mapStateToProps)(Login));
// export default connect(mapStateToProps)(Login);
