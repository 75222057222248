import {put, takeLatest} from 'redux-saga/effects';
import {push} from 'connected-react-router';

import * as authActions from './actions';
import firebase from 'firebase/app';
import 'firebase/auth';

function* trySignIn(action) {
	try {
		const response = yield signIn(
			action.authData.login,
			action.authData.password,
		);
		if (response.error) {
			yield put(authActions.signInError(response.error));
		} else {
			const user = yield checkIfAdmin(response.user.uid);
			if (user.exists) {
				console.log('Document data:', user.data());
				yield put(
					authActions.signInSuccess(
						response,
						action.authData.email,
						user.data().admin,
					),
				);
			} else {
				console.log('No such document!');
			}
		}
	} catch (e) {
		yield put(authActions.signInError(e.message));
	}
}

function* signIn(email, password) {
	return yield firebase.auth().signInWithEmailAndPassword(email, password);
}

function* checkIfAdmin(uid) {
	return yield firebase.firestore().collection('users').doc(uid).get();
}

function* goToMain() {
	yield put(push('/'));
}

const authSagas = [
	takeLatest(authActions.SIGN_IN, trySignIn),
	takeLatest(authActions.SIGN_IN_SUCCESS, goToMain),
];

export default authSagas;
