import React from 'react';
import {connect} from 'react-redux';
import 'firebase/auth';
import {MainContainer} from '../components/Container';
import {withRouter} from 'react-router-dom';
import {UserListItem} from '../components/List';
import {authActions, authSelectors} from '../modules/auth';
import {usersActions, usersSelectors} from '../modules/users';
import firebase from 'firebase/app';
import 'firebase/auth';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class Users extends React.Component {
	state = {
		addClicked: false,
		incompleteData: false,
		user: {security: false, name: null, email: null, pass: null, plate: ''},
		showDeleteModal: false,
	};

	handleChange = (key, value) => {
		this.setState({
			user: {
				...this.state.user,
				[key]: value,
			},
		});
	};

	handleCreateUser = (event) => {
		event.preventDefault();
		this.setState({addClicked: true, incompleteData: false});
		if (
			this.state.user.name === null ||
			this.state.user.email === null ||
			this.state.user.pass === null
		) {
			this.setState({incompleteData: true});
		} else {
			firebase
				.auth()
				.currentUser.getIdToken()
				.then((token) => {
					this.props.dispatch(usersActions.createUser(this.state.user, token));
				});
		}
	};

	onBlockPress = (user) => {
		if (user.disabled) {
			this.props.dispatch(usersActions.changeBlock(user.id, false));
		} else {
			this.props.dispatch(usersActions.changeBlock(user.id, true));
		}
	};

	showDeleteModal = (user) => {
		this.setState({showDeleteModal: true, userToDelete: user});
	};

	onDeletePress = (user) => {
		this.setState({showDeleteModal: false});
		firebase
			.auth()
			.currentUser.getIdToken()
			.then((token) => {
				this.props.dispatch(usersActions.deleteUser(user.id, token));
			});
	};

	componentDidMount() {
		this.props.dispatch(usersActions.fetchUsers());
	}

	componentWillUnmount() {}

	render() {
		return (
			<MainContainer
				onLogoutClick={() => this.props.dispatch(authActions.signOut())}>
				<div className="container">
					<div className="row" style={{paddingTop: 10}}>
						<div className="col-md-3">
							<form>
								<div className="form-group">
									<label className="control-label" htmlFor="username">
										Username
									</label>
									<input
										className="form-control"
										id="name"
										type="text"
										placeholder="Name"
										required
										onChange={(event) => {
											this.handleChange('name', event.target.value);
										}}
									/>
								</div>

								<div className="form-group">
									<label className=" control-label" htmlFor="emailId">
										E-mail
									</label>
									<input
										className="form-control"
										id="emailId"
										type="text"
										placeholder="E-mail"
										required
										onChange={(event) => {
											this.handleChange('email', event.target.value);
										}}
									/>
								</div>

								<div className="form-group">
									<label className=" control-label" htmlFor="passwordId">
										Password
									</label>
									<input
										className="form-control"
										id="passwordId"
										type="password"
										placeholder="Password"
										required
										onChange={(event) => {
											this.handleChange('pass', event.target.value);
										}}
									/>
								</div>

								<div className="form-group">
									<label className=" control-label" htmlFor="plateId">
										Plate
									</label>
									<input
										className="form-control"
										id="plateId"
										type="text"
										placeholder="Eg. WI027HJ"
										required
										onChange={(event) => {
											this.handleChange('plate', event.target.value);
										}}
									/>
								</div>

								<div className="form-check" style={{paddingBottom: 15}}>
									<input
										type="checkbox"
										checked={this.state.user.security}
										className="form-check-input"
										id="security"
										onChange={() => {
											this.handleChange('security', !this.state.user.security);
										}}
									/>
									<label className="form-check-label" htmlFor="security">
										Account for reception
									</label>
								</div>

								<div className="form-group justify-content-center">
									<span>
										<button
											disabled={this.props.isCreating}
											onClick={this.handleCreateUser}
											className="btn btn-lg btn-success"
											type="submit">
											ADD&nbsp;
											{this.props.isCreating ? (
												<i
													className=" fa fa-circle-o-notch fa-spin"
													style={{fontSize: '20px'}}
												/>
											) : null}
										</button>
									</span>
								</div>

								{this.props.isCreated && this.state.addClicked ? (
									<div className="alert alert-success" role="alert">
										User has been added sucessfully.
									</div>
								) : null}
								{this.props.errorCreating &&
								this.state.addClicked &&
								!this.state.incompleteData ? (
									<div className="alert alert-danger" role="alert">
										Error while creating user.
									</div>
								) : null}
								{this.state.incompleteData && this.state.addClicked ? (
									<div className="alert alert-danger" role="alert">
										Please enter name, email and password.
									</div>
								) : null}
							</form>
						</div>
						<div className="col-md-9">
							<table className="table table-light table-striped">
								<thead style={{backgroundColor: '#40B5A1', color: 'white'}}>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Name</th>
										<th scope="col">E-mail</th>
										<th scope="col">Plate</th>
										<th scope="col">Reception</th>
										<th scope="col">Delete</th>
									</tr>
								</thead>
								<tbody>
									{Object.values(this.props.users).map((user, index) => (
										<UserListItem
											key={user.email}
											number={index + 1}
											user={user}
											onBlockPress={() => this.showDeleteModal(user)}
										/>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<Modal
					show={this.state.showDeleteModal}
					onHide={() => this.setState({showDeleteModal: false})}>
					<Modal.Header closeButton>
						<Modal.Title>Confirm delete</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							Are you sure you want to delete{' '}
							<b>{this.state.userToDelete?.name}</b>?
						</p>
						<p>
							<b>WARNING:</b> This cannot be undone!
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => this.setState({showDeleteModal: false})}>
							Close
						</Button>
						<Button
							variant="danger"
							onClick={() => this.onDeletePress(this.state.userToDelete)}>
							Delete
						</Button>
					</Modal.Footer>
				</Modal>
			</MainContainer>
		);
	}
}

const mapStateToProps = (state) => {
	const users = usersSelectors.dataSelector(state);
	const isCreating = usersSelectors.isCreatingSelector(state);
	const email = authSelectors.emailSelector(state);
	const isCreated = usersSelectors.isCreatedSelector(state);
	const errorCreating = usersSelectors.errorCreatingSelector(state);

	return {
		users,
		isCreating,
		email,
		isCreated,
		errorCreating,
	};
};

export default withRouter(connect(mapStateToProps)(Users));
