export const dataSelector = (state) => {
	return state.lockers.data || [];
};

export const errorSelector = (state) => {
	return state.lockers.error;
};

export const isFetchingSelector = (state) => {
	return state.lockers.isFetching;
};
