import React from 'react';
import {Provider} from 'react-redux';
import {store, persistor, history} from './config/store';
import {PersistGate} from 'redux-persist/integration/react';
import './App.css';
import Login from './screens/Login';
import Main from './screens/Main';
import Users from './screens/Users';
import {initFirebase} from './config/firebase';
import 'bootstrap/dist/css/bootstrap.css';
import {Switch, Route, Redirect} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import UserActions from './screens/UserActions';

initFirebase();

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}) {
	return (
		<Route {...rest}>
			{store.getState().auth.user && store.getState().auth.isAdmin ? (
				children
			) : (
				<Redirect
					to={{
						pathname: '/login',
					}}
				/>
			)}
		</Route>
	);
}

function App() {
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<PersistGate loading={null} persistor={persistor}>
					<Switch>
						<PrivateRoute path="/" exact>
							<Main />
						</PrivateRoute>
						<PrivateRoute path="/users" exact>
							<Users />
						</PrivateRoute>
						<Route path="/login">
							<Login />
						</Route>
						<Route path="/user-actions">
							<UserActions />
						</Route>
					</Switch>
				</PersistGate>
			</ConnectedRouter>
		</Provider>
	);
}

export default App;
