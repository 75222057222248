import React from 'react';
import 'firebase/auth';
import {LoginContainer} from '../components/Container';
import {withRouter} from 'react-router-dom';
import firebase from 'firebase/app';

class UserActions extends React.Component {
	state = {
		password: '',
		action: '',
		title: '',
	};

	componentDidMount() {
		const url_string = window.location.href;
		const url = new URL(url_string);

		const mode = url.searchParams.get('mode');
		const actionCode = url.searchParams.get('oobCode');

		this.setState({actionCode: actionCode, mode: mode});

		switch (mode) {
			case 'resetPassword':
				this.setState({title: 'Reset password'}, () =>
					this.verifyResetPassword(),
				);
				break;
			case 'recoverEmail':
				this.setState({title: 'Recover email'});
				break;
			case 'verifyEmail':
				this.setState({title: 'Verify Email'}, () => {
					this.handleVerifyEmail();
				});
				break;
			default:
			// Error: invalid mode.
		}
	}

	handleChange = (key, value) => {
		this.setState({[key]: value});
	};

	handleResetPassword = (event) => {
		event.preventDefault();
		this.setState({isResetting: true});
		firebase
			.auth()
			.confirmPasswordReset(this.state.actionCode, this.state.password)
			.then(() => {
				this.setState({resetComplete: true, isResetting: false});
			})
			.catch(() => {
				// Error occurred during confirmation. The code might have expired or the
				// password is too weak.
				this.setState({passwordResetError: true, isResetting: false});
			});
	};

	handleRecoverEmail = (actionCode) => {
		// Localize the UI to the selected language as determined by the lang
		// parameter.
		var restoredEmail = null;
		// Confirm the action code is valid.
		firebase
			.auth()
			.checkActionCode(actionCode)
			.then(function (info) {
				// Get the restored email address.
				restoredEmail = info.data.email;

				// Revert to the old email.
				return firebase.auth().applyActionCode(actionCode);
			})
			.then(() => {
				// Account email reverted to restoredEmail

				// TODO: Display a confirmation message to the user.

				// You might also want to give the user the option to reset their password
				// in case the account was compromised:
				firebase
					.auth()
					.sendPasswordResetEmail(restoredEmail)
					.then(() => {
						// Password reset confirmation sent. Ask user to check their email.
						this.setState({emailRecovered: true});
					})
					.catch(() => {
						// Error encountered while sending password reset code.
					});
			})
			.catch(() => {
				// Invalid code.
			});
	};

	handleVerifyEmail = () => {
		// Try to apply the email verification code.
		firebase
			.auth()
			.applyActionCode(this.state.actionCode)
			.then(() => {
				this.setState({emailVerified: true});
				// Email address has been verified.
			})
			.catch(() => {
				// Code is invalid or expired. Ask the user to verify their email address
				// again.
				this.setState({codeValid: false});
			});
	};

	verifyResetPassword = () => {
		// Verify the password reset code is valid.
		firebase
			.auth()
			.verifyPasswordResetCode(this.state.actionCode)
			.then((email) => {
				this.setState({codeValid: true, email: email});
			})
			.catch(() => {
				// Invalid or expired action code. Ask user to try to reset the password
				// again.
				this.setState({codeValid: false});
				return (
					<div
						className="alert alert-danger alert-dismissible fade show"
						role="alert">
						Invalid or expired request. Please try to reset the password again.
					</div>
				);
			});
	};

	renderResetPassword = () => {
		if (this.state.codeValid && !this.state.resetComplete) {
			return (
				<div>
					<div className="form-group">
						<label className=" control-label">
							New password for {this.state.email}
						</label>
						<input
							className="form-control"
							type="password"
							placeholder="New password"
							value={this.state.password}
							onChange={(event) =>
								this.handleChange('password', event.target.value)
							}
						/>
					</div>

					<div className="form-group justify-content-center">
						<span>
							<button
								disabled={this.state.isResetting}
								onClick={this.handleResetPassword}
								className="btn btn-success"
								style={{background: '#40B5A1'}}
								type="submit">
								Reset
								{this.state.isResetting ? (
									<i
										className=" fa fa-circle-o-notch fa-spin"
										style={{fontSize: '20px'}}
									/>
								) : null}
							</button>
						</span>
					</div>
				</div>
			);
		} else if (this.state.resetComplete) {
			return (
				<div className="alert alert-success" role="alert">
					Password changed succesfully.
				</div>
			);
		} else if (
			this.state.codeValid !== null &&
			this.state.codeValid === false
		) {
			return (
				<div
					className="alert alert-danger alert-dismissible fade show"
					role="alert">
					Invalid or expired request. Please try to reset the password again.
				</div>
			);
		}
	};

	renderRecoverEmail = () => {};

	renderVerifyEmail = () => {
		if (this.state.emailVerified) {
			return (
				<div className="alert alert-success" role="alert">
					Email verified successfully.
				</div>
			);
		} else if (
			this.state.codeValid !== null &&
			this.state.codeValid === false
		) {
			return (
				<div
					className="alert alert-danger alert-dismissible fade show"
					role="alert">
					Invalid or expired request. Please try again.
				</div>
			);
		}
		return (
			<i className=" fa fa-circle-o-notch fa-spin" style={{fontSize: '20px'}} />
		);
	};

	render() {
		return (
			<LoginContainer title={this.state.title}>
				<form>
					{this.state.mode === 'resetPassword' ? (
						this.renderResetPassword()
					) : this.state.mode === 'verifyEmail' ? (
						this.renderVerifyEmail()
					) : this.state.mode === 'recoverEmail' ? (
						this.renderRecoverEmail()
					) : (
						<div>An error occured</div>
					)}
				</form>
			</LoginContainer>
		);
	}
}

export default withRouter(UserActions);
