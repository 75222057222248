import * as firebase from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyBjgVNn4CAtMGr1Do3NhtbPilB-z5r6ILw',
	authDomain: 'msprking-cec86.firebaseapp.com',
	databaseURL: 'https://msprking-cec86.firebaseio.com',
	projectId: 'msprking-cec86',
	storageBucket: 'msprking-cec86.appspot.com',
	messagingSenderId: '1054726665665',
	appId: '1:1054726665665:web:c9c4178eab5bd0c07c6b40',
	measurementId: 'G-D6RZBXYEL6',
};

export function initFirebase() {
	firebase.initializeApp(firebaseConfig);
}
