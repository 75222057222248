import {
	FETCH,
	FETCH_SUCCESS,
	FETCH_ERROR,
	CREATE,
	CREATE_SUCCESS,
	CREATE_ERROR,
	CHANGE_BLOCK,
	CHANGE_BLOCK_SUCCESS,
	CHANGE_BLOCK_ERROR,
	DELETE,
	DELETE_SUCCESS,
	DELETE_ERROR,
} from './actions';

const initialState = {
	data: [],
	error: null,
	isFetching: false,
	isCreating: false,
	isCreated: false,
	errorCreating: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH:
			return {
				...state,
				error: null,
				isFetching: true,
			};
		case FETCH_SUCCESS:
			return {
				...state,
				data: action.result,
				error: null,
				isFetching: false,
			};
		case FETCH_ERROR:
			return {
				...state,
				data: null,
				error: action.error,
				isFetching: false,
			};

		case CREATE:
			return {
				...state,
				error: null,
				isCreating: true,
				errorCreating: false,
				isCreated: false,
			};
		case CREATE_SUCCESS:
			return {
				...state,
				data: {
					[action.result.uid]: {id: action.result.uid, ...action.result},
					...state.data,
				},
				error: null,
				isCreating: false,
				isCreated: true,
				errorCreating: false,
			};
		case CREATE_ERROR:
			return {
				...state,
				error: action.error,
				isCreating: false,
				isCreated: false,
				errorCreating: true,
			};

		case CHANGE_BLOCK:
			return {
				...state,
				data: {
					...state.data,
					[action.userId]: {
						...state.data[action.userId],
						changingBlock: true,
					},
				},
				error: null,
			};
		case CHANGE_BLOCK_SUCCESS:
			return {
				...state,
				data: {
					...state.data,
					[action.result.uid]: {
						...state.data[action.result.uid],
						changingBlock: false,
						...action.result,
					},
				},
				error: null,
			};
		case CHANGE_BLOCK_ERROR:
			return {
				...state,
				data: {
					...state.data,
					[action.userId]: {
						...state.data[action.userId],
						changingBlock: false,
					},
				},
				error: action.error,
			};

		case DELETE:
			return {
				...state,
				data: {
					...state.data,
					[action.userId]: {
						...state.data[action.userId],
						isDeleting: true,
					},
				},
				error: null,
				errorDeleting: false,
			};
		case DELETE_SUCCESS:
			return {
				...state,
				data: Object.values(state.data).filter(
					(item) => item.id !== action.userId,
				),
				error: null,
				errorDeleting: false,
			};
		case DELETE_ERROR:
			return {
				...state,
				data: {
					...state.data,
					[action.userId]: {
						...state.data[action.userId],
						isDeleting: false,
					},
				},
				error: action.error,
				errorDeleting: true,
			};
		default:
			return state;
	}
};

export default reducer;
