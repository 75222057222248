export const FETCH = 'prkingBarrier/lockers/FETCH';
export const FETCH_SUCCESS = 'prkingBarrier/lockers/FETCH_SUCCESS';
export const FETCH_ERROR = 'prkingBarrier/lockers/FETCH_ERROR';
export const UPDATE = 'prkingBarrier/lockers/UPDATE';

export const fetchLockers = () => ({
	type: FETCH,
});

export const fetchLockersSuccess = (result) => ({
	type: FETCH_SUCCESS,
	result,
});

export const fetchLockersError = (error) => ({
	type: FETCH_ERROR,
	error,
});

export const updateLocker = (locker) => ({
	type: UPDATE,
	locker,
});
