import {SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_ERROR} from './actions';

const initialState = {
	token: null,
	activeMemberUuid: null,
	email: null,
	error: null,
	user: null,
	isSigningIn: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SIGN_IN:
			return {
				...state,
				error: null,
				isSigningIn: true,
			};
		case SIGN_IN_SUCCESS:
			return {
				...state,
				user: action.result,
				isAdmin: action.admin,
				error: null,
				isSigningIn: false,
			};
		case SIGN_IN_ERROR:
			return {
				...state,
				user: null,
				error: action.error,
				isSigningIn: false,
			};
		default:
			return state;
	}
};

export default reducer;
