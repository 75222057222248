export const tokenSelector = (state) => {
	return state.auth.token;
};

export const activeMemberUuidSelector = (state) => {
	return state.auth.activeMemberUuid;
};

export const emailSelector = (state) => {
	return state.auth.user ? state.auth.user.user.email : null;
};

export const isAdminSelector = (state) => {
	return state.auth ? state.auth.isAdmin : false;
};

export const errorSelector = (state) => {
	return state.auth.error;
};

export const isSigningInSelector = (state) => {
	return state.auth.isSigningIn;
};
