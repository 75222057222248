import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import ParkingSpot from './ParkingSpot';

const getSpotColor = (occupied) => {
	if (!occupied) {
		return '#40B5A1';
	} else {
		return '#F15E22';
	}
};

const ParkingSpotDetails = ({occupied, name, batteryLevel, occupiedBy}) => {
	return (
		<div className="row">
			<div className="col-sm-12">
				<div className="card bg-white mb-3 mx-auto card-default">
					<div
						className="card-header main-color-header"
						style={{background: getSpotColor(occupied)}}>
						<p>Spot: {name}</p>
					</div>
					<div className="card-body">
						<div className="card-text">
							<p>
								<b>{occupied ? 'OCCUPIED BY:' : 'FREE'}</b>
							</p>
							<p>
								<b>{occupied && occupiedBy ? occupiedBy : ''}</b>
							</p>
							<p>
								<b>{`Battery level: ${
									batteryLevel !== null && batteryLevel !== undefined
										? batteryLevel
										: 'unknown'
								}`}</b>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ParkingSpot.propTypes = {
	occupied: PropTypes.bool,
	name: PropTypes.string,
};

export default ParkingSpotDetails;
