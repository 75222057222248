import {put, takeLatest} from 'redux-saga/effects';

import * as lockersActions from './actions';
import firebase from 'firebase/app';
import 'firebase/firestore';

function* fetchLockers() {
	try {
		const response = yield fetchFromFirebase();
		if (response.error) {
			yield put(lockersActions.fetchLockersError(response.error));
		} else {
			const docList = {};
			response.forEach((doc) => {
				docList[doc.id] = {
					id: doc.id,
					name: doc.data().displayName,
					occupied: !doc.data().isUp,
					batteryLevel: doc.data().batteryLevel,
					occupiedBy: doc.data().occupiedBy,
					hidden: doc.data().hidden,
				};
			});
			yield put(lockersActions.fetchLockersSuccess(docList));
		}
	} catch (e) {
		yield put(lockersActions.fetchLockersError(e.message));
	}
}

function* fetchFromFirebase() {
	return yield firebase.firestore().collection('lockers').get();
}

const lockersSagas = [takeLatest(lockersActions.FETCH, fetchLockers)];

export default lockersSagas;
